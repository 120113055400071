/* Header.css */


@media (min-width: 1100px) {
    .leftC {
        
        position: relative;
        left: 150px;
    }

}


/* Header.css */

/* @media (min-width: 1300px) {
  .leftC {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: 800px; 
  }
  .rightC {
    position:relative;
    right: -850px;
  }
} */

    
    

  

  @media (max-width: 600px){
    .rightC{
        margin-top: -70px;
    }    
  }